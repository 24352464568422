import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p className="fw-normal">

          <a href="https://www.sdb.ovh" target="_blank" rel="noreferrer">
            SdB
          </a>{" - "}
          {d.getFullYear()}
          {" - "}
          <a href="/fr/">FR</a>
          {" - "}
          <a href="/en/">EN</a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
