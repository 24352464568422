import { createSlice } from '@reduxjs/toolkit'

export const OpenpdfSlice = createSlice({
    name: 'openpdf',
    initialState: {
        hideDropzone: false,
        value: 0,
        infos: {
            filename: '',
            numPages: 0
        },
        deletePages: [],
        base64: '',
        images: [],
        files: [],
    },
    reducers: {
        setBase64: (state, action) => {
            state.deletePages = []
            state.base64 = action.payload
        },
        setHideDropzone: (state, action) => {
            state.hideDropzone = action.payload
        },
        setInfo: (state, action) => {
            state.infos = action.payload
        },
        setPdfNumPages: (state, action) => {
            state.infos.numPages = action.payload
        },
        deletePage: (state, action) => {

            if (-1 === state.deletePages.indexOf(action.payload)) {
                state.deletePages.push(action.payload)
            } else {
                state.deletePages.splice(state.deletePages.indexOf(action.payload), 1)
                // return state.deletePages
            }

        },
        togglePages: (state) => {
            var newDeletePages = []
            for (var i = 0; i < state.infos.numPages; i++) {
                if (-1 === state.deletePages.indexOf(i)) {
                    newDeletePages.push(i)
                }
            }
            state.deletePages = newDeletePages
        },
        setFilename: (state, action) => {
            state.infos.filename = action.payload
        },
        increment: state => {
            state.value += 1
        },
        pushPreview: (state, action) => {
            state.images.push(action.payload)
        },
        addFile: (state, action) => {
            action.payload.sort = 1 + state.files.length
            action.payload.id = Math.floor(10000 + Math.random() * 89999) + action.payload.filename
            state.files.push(action.payload)
        },
        removeAllFiles: (state) => {
            state.files = []
        },
        removeFile: (state, action) => {
            // Construct a new array immutably
            const newFiles = state.files.filter(file => file.id !== action.payload)
            // "Mutate" the existing state to save the new array
            state.files = newFiles
        },
        sortFiles: (state, action) => {
            const oldIndex = action.payload.source
            const newIndex = action.payload.dest

            // https://medium.com/kevin-salters-blog/reordering-a-javascript-array-based-on-a-drag-and-drop-interface-e3ca39ca25c
            const movedItem = state.files[oldIndex]
            const remainingItems = state.files.filter((item, index) => index !== oldIndex);
            const newFiles = [
                ...remainingItems.slice(0, newIndex),
                movedItem,
                ...remainingItems.slice(newIndex)
            ];
            state.files = newFiles
        }
    }
})


export const { setBase64, setInfo, setPdfNumPages, setFilename, deletePage, togglePages,
    setHideDropzone, increment, pushPreview, addFile, removeAllFiles, removeFile, sortFiles } = OpenpdfSlice.actions
export const base64 = state => state.openpdf.base64
export const infos = state => state.openpdf.infos
export const hideDropzone = state => state.openpdf.hideDropzone
export const pagesToDelete = state => state.openpdf.deletePages
export const files = state => state.openpdf.files

export default OpenpdfSlice.reducer