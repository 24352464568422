// import { lazy, Suspense, useEffect } from 'react';
// import { lazy, Suspense } from 'react';
//import { Suspense } from 'react';

/// Components
import Index from "./jsx";
//import { connect, useDispatch } from 'react-redux';
//import { connect } from 'react-redux';
// import { Route, Switch, withRouter } from 'react-router-dom';
//import { withRouter } from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';

// action
// import { checkAutoLogin } from './services/AuthService';
// import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/monstyle.css";

/*

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
    });
});

*/

function App(props) {
    /*
        const dispatch = useDispatch();
    
        useEffect(() => {
            checkAutoLogin(dispatch, props.history);
        }, [dispatch, props.history]);


    let routes = (
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/page-register' component={SignUp} />
            <Route path='/page-forgot-password' component={ForgotPassword} />
        </Switch>
    );

    */


    return (
        <>
            <HelmetProvider>
                <Index />
            </HelmetProvider>

        </>
    );

    /*
    
        <>
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
            }
            >
   
            </Suspense>
        </>
        */

};

/*

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};
*/

// export default withRouter(connect(mapStateToProps)(App));
export default App;

