import React, { useContext, Suspense } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

import i18n from 'i18next';

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

import { ThemeContext } from "../context/ThemeContext";


import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
import Nav from "./layouts/nav";
import { Helmet } from "react-helmet-async";


const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const Splitpage = React.lazy(() => import('../features/split/Splitpage'));
const Mergepage = React.lazy(() => import('../features/merge/Mergepage'));
const Rotatepage = React.lazy(() => import('../features/rotate/Rotatepage'));
const Page404 = React.lazy(() => import('./pages/Page404'));

const Markup = () => {


  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "about", component: About },
    { url: "split", component: Splitpage },
    { url: "merge", component: Mergepage },
    { url: "rotate", component: Rotatepage }
  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }} />
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid p-4" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >

            <Suspense fallback={<div></div>}>
              <Switch>


                {routes.map((data, i) => (

                  <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                  />

                ))}
                <Route path="*">
                  <Page404 />
                </Route>
              </Switch>

            </Suspense>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

/*
<Setting />
*/

export default Markup;
