import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
// import reportWebVitals from "./reportWebVitals";
// import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";

import "./i18n";
import i18n from "i18next";




const baseUrl = "/" + i18n.language;

// <BrowserRouter basename='/react/demo'>
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>

            <BrowserRouter basename={baseUrl}>
                <ThemeContext>
                    <App />
                </ThemeContext>
            </BrowserRouter>

        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
// reportWebVitals();