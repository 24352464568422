import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { resources } from "./translations";

i18n.on("languageChanged", function (lng) {
  // console.log('language changed 8')

  if ('en' === lng && -1 === window.location.pathname.indexOf('/en')) {
    const newUrl = '/en';
    window.location.replace(newUrl)
  }

  if ('fr' === lng && -1 === window.location.pathname.indexOf('/fr')) {
    const newUrl = '/fr';
    window.location.replace(newUrl)
  }


})


i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    supportedLngs: ["en", "fr"],
    debug: true,
    detection: {
      order: ["path", "navigator"],
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
