import React from "react";
/*
import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/user.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from './Logout';
*/
import { useTranslation } from "react-i18next";

// import { makeStyles } from '@material-ui/core/styles'

// import i18n from "i18next";

import {
	useLocation
} from "react-router-dom";


const Header = ({ onNote }) => {

	const { t } = useTranslation();
	let location = useLocation();

	var path = location.pathname.split("/");

	while (path.length < 2) {
		path.push("")
	}

	var nomPage = '';


	switch (path[1]) {
		case '':
			nomPage = t('Home')
			break;
		case 'about':
			nomPage = t('About')
			break;

		case 'merge':
			nomPage = t('Merge')
			break;
		case 'split':
			nomPage = t('Split')
			break;
		case 'rotate':
			nomPage = t('Rotate')
			break;
		default:

	}

	return (
		<div className="header border-bottom">
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						<div className="header-left">
							<div
								className="dashboard_bar"
								style={{ textTransform: "capitalize" }}
							>
								{nomPage}
							</div>
						</div>

					</div>
				</nav>
			</div>
		</div>
	);
};

export default Header;
