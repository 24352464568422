const resources = {
  en: {
    translation: {
      route_home: "/en/home",
      English: "English",
      French: "Français",
      About: "About",
      Home: "Home",
      DragnDrop: "Drag 'n' drop some files here, or click to select files",
      Drophere: "Drop the files here",
      HomeSubtitle:
        "Edit your PDF files in your browser. With Massicot PDF, you can separate, rearrange or delete pages from a pdf file",
      Securite: "Safety",
      Securitetext:
        "Everything is done in your web browser, on your device. Your files are not processed on our servers",
      Speed: "Fast",
      Speedtext:
        "Big file ? Slow network ? No problem, your files don't need to be uploaded to a remote server",
      Nolimit: "No limit",
      Nolimittext:
        "No subscription. No payment. No email required. No account registration",
      About1:
        "PDF documents are everywhere ! Invoices, contracts, scanned documents, we are all using PDF. This file format, very convenient, has a major drawback : they are difficult to edit. There are software and online services, more or less free and more or less complex .Massicot PDF is an alternative which is based on 3 principles :",
      Security1:
        "You cannot entrust your documents to just anyone. Massicot PDF processes your documents on your device and in your browser. We do not have access to your documents. Never",
      Speed1:
        "Massicot PDF opens quickly. Your file does not need to be sent to a server somewhere on the internet. Open, edit, save. It's as simple as that",
      Nolimit1:
        "This service is free. There is no limit of pages or documents. We do not need your email address or your credit card number. The power of your device is the only limit to the use of Massicot PDF",
      maintitle: "Manipulate PDF in your brower with Massicot PDF",
      Split: "Split",
      SplitTitle: "Split a PDF",
      ToggleSelection: "Toggle",
      ClosePDF: "Close",
      SavePDF: "Save",
      Merge: "Merge",
      MergeTitle: "Merge PDF",
      FilenameColumn: "Files",
      splitTitle: "Split a PDF file",
      splitText:
        "Extract and reorder pages from a PDF file. Remove unecessary blank pages easily",
      mergeTitle: "Merge PDF files together",
      mergeText:
        "Assemble several PDF files into a single PDF file. No more emails with tons of attachments !",
      availableTools: "Available tools",
      cookieconsent: "This website uses cookies to enhance the user experience",
      cookieok: "I understand",
      separateAll: "Split all",
      SplitTip:
        "Select the pages you want to keep, reorganize the document by clicking and dragging the pages, or get all the pages in a compressed file",
      MergeTip: "Group your PDF files and put them in the order you want",
      HomeDesc:
        "Massicot PDF is an online service for processing PDF files directly in your web browser, free and easy to use. Split PDF, merge PDF or rotate pages !",
      AboutDesc:
        "About Massicot PDF : this is an online service which help you to split, merge or rotate PDF files in your web browser",
      SplitDesc:
        "Cut a PDF, split pages PDF, extract from a PDF, quickly, freely and without any software",
      SplitHint: "Upload your file, Remove or sort pages, and download your files or even each pages  independently.",
      MergeDesc:
        "Assemble PDF, paste PDF, group PDF, quickly, freely and without any software",
      MergeHint: "Combine PDFs in the order you want with the easiest PDF merger available.",
      Gotosplit: "Split",
      Rotate: 'Rotate',
      RotateTitle: 'Rotate pages in a PDF file',
      RotateDesc: 'Rotate one or several pages of a PDF file easily with Massicot PDF',
      RotateHint: "Upload your file, rotate one, some or every pages of your document and download your file.",
    },
  },
  fr: {
    translation: {
      route_home: "/fr/accueil",
      English: "English",
      French: "Français",
      About: "A propos",
      Home: "Accueil",
      DragnDrop: "Déposez des fichiers ici, ou cliquez pour les selectionner",
      Drophere: "Déposez les fichiers ici",
      HomeSubtitle:
        "Editez vos fichiers PDF dans votre navigateur.Avec Massicot PDF, vous pouvez séparer, réorganiser ou supprimer des pages d'un fichier pdf",
      Securite: "En toute sécurité",
      Securitetext:
        "Tout est fait dans votre navigateur web, sur votre appareil. Vos fichiers ne sont pas transformés sur un de nos serveurs",
      Speed: "A toute vitesse",
      Speedtext:
        "Fichier volumineux ? Mauvaise connexion réseau ? Aucun problème, vos fichiers n'ont pas à être envoyés sur un serveur distant",
      Nolimit: "Sans limite",
      Nolimittext:
        "Pas d'abonnement. Pas de paiement. Pas d'email à donner ni de compte à créer",
      About1:
        "Les fichiers PDF sont partout ! Factures, contrats, documents scannés, nous avons tous utilisés des documents PDF. Ce format de fichier,        très pratique, a un inconvénient de taille: ils sont difficiles à modifier.Il existe des logiciels et des services en ligne, plus ou moins gratuits        et plus ou moins complexes.Massicot PDF est une alternative qui se base sur 3 principes : ",
      Security1:
        "Vous ne pouvez pas confier vos documents à n'importe qui. Massicot PDF traite vos documents sur votre appareil et dans votre navigateur. Nous n'avons pas accès à vos documents. Jamais",
      Speed1:
        "Massicot PDF s'ouvre vite. Votre fichier n'a pas besoin d'etre envoyé sur un serveur quelque part sur internet. Ouvrez, modifiez, enregistrer. C'est aussi simple que cela",
      Nolimit1:
        "Ce service est gratuit. Il n'y a pas de limite de pages ou de documents.Nous n'avons pas besoin de votre adresse mail ni de votre numéro de carte bleue.La puissance de votre appareil et la seule limite à l'utilisation de Massicot PDF",
      maintitle:
        "Editez vos fichiers PDF dans votre navigateur avec Massicot PDF",
      Split: "Découper",
      SplitTitle: "Découper un PDF",
      ToggleSelection: "Inverser",
      ClosePDF: "Fermer",
      SavePDF: "Enregistrer",
      Merge: "Fusionner",
      MergeTitle: "Fusionner des PDF",
      FilenameColumn: "Fichiers",

      splitTitle: "Découper un fichier PDF",
      splitText:
        "Récupérez et réorganisez les pages d'un fichier PDF. Par exemple, vous pouvez enlever les pages vierges facilement",
      mergeTitle: "Fusionner des fichiers PDF",
      mergeText:
        "Regroupez plusieurs fichiers PDF dans un seul fichier. Fini les mails avec trop de pièces jointes",
      availableTools: "Outils disponibles",
      cookieconsent:
        "Ce site internet utilise des cookies pour améliorer l'expérience utilisateur",
      cookieok: "Je comprends",
      separateAll: "Tout séparer",
      SplitTip:
        "Sélectionnez la ou les pages à conserver, réorganisez le document en faisant glisser les pages, ou alors récupérez chaque page dans un fichier compressé",
      MergeTip:
        "Groupez et vos fichiers PDF et mettez dans l'ordre que vous voulez",
      HomeDesc:
        "Massicot PDF est un service pour manipuler des PDF dans votre navigateur. Divisez, fusionnez ou retournez des PDF n'a jamais été aussi facile",
      AboutDesc:
        "A propos de Massicot PDF : c'est un service en ligne pour modifier des fichiers PDF dans votre navigateur web",
      SplitDesc:
        "Divisez un fichier PDF par intervalle de pages ou extrayez toutes les pages en plusieurs fichiers PDF. Diviser ou extraire des fichiers PDF en ligne, facilement et gratuitement. Découper un PDF, séparer les pages d'un PDF, extraction depuis un PDF, rapidement, gratuitement, et sans logiciel",
      SplitHint: "Choisissez votre fichier, déplacez les pages, supprimer des pages ou convertir chaque page du document en fichier PDF indépendant.",
      MergeDesc:
        "Sélectionnez plusieurs fichiers PDF et fusionnez-les en quelques secondes. Fusionnez et combinez des fichiers PDF en ligne, facilement et gratuitement. Assembler des PDF, coller des PDF, grouper des PDF, rapidement, gratuitement, et sans logiciel",
      MergeHint: "Fusionner et combiner des fichiers PDF et les mettre dans l'ordre que vous voulez. C'est très facile et rapide !",
      Gotosplit: "Découper",
      Rotate: 'Tourner',
      RotateTitle: 'Tourner une page d\'un fichier PDF',
      RotateDesc: 'Tourner une ou plusieurs pages d\'un fichier PDF',
      RotateHint: "Choisissez votre fichier, tournez une ou plusieurs pages à 90° puis téléchargez votre fichier modifé.",
    },
  },
};
export { resources };
