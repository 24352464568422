/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
/// Link
import { Link } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { useTranslation } from 'react-i18next';


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {

  const { t } = useTranslation();

  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);

  //console.log(sidebarposition);
  //console.log(sidebarLayout);

  useEffect(() => {

    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector('.heart');
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener('click', heartBlast);

    var lnks = document.querySelectorAll("ul.metismenu a");
    lnks.forEach(lnk => {
      lnk.addEventListener('click', (e) => {

        document.querySelector('li.mm-active').classList.remove('mm-active')

        e.target.closest('li').classList.add('mm-active')

      });
    });


  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];


  /// Active menu
  let home = "",
    about = ["about"],
    split = ["split"],
    merge = ['merge'],
    rotate = ['rotate'];

  return (
    <div
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      {/* <Dropdown className="dropdown header-profile2">
				<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
					<div className="header-info2 d-flex align-items-center border">
						<img src={profile} width={20} alt="" />
						<div className="d-flex align-items-center sidebar-info">
							<div>
								<span className="font-w700 d-block mb-2">Eren Yeager</span>
								<small className="text-end font-w400">Super Admin</small>
							</div>
							<i className="fas fa-sort-down ms-4"></i>
						</div>
					</div>
				</Dropdown.Toggle>
				<Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
					<Link to="/app-profile" className="dropdown-item ai-icon">
						<svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary me-1"
						  width={18} height={18} viewBox="0 0 24 24" fill="none"
						  stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
						>
							<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
							<circle cx={12} cy={7} r={4} />
						</svg>
						<span className="ms-2">Profile </span>
					</Link>
					<Link to="/email-inbox" className="dropdown-item ai-icon">
						<svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" className="text-success me-1" width={18}
							height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2}
							strokeLinecap="round" strokeLinejoin="round"
						>
							<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
							<polyline points="22,6 12,13 2,6" />
						</svg>
						<span className="ms-2">Inbox</span>
					</Link>
					<LogoutPage />
				</Dropdown.Menu>
			</Dropdown>  */}
      <MM className="metismenu" id="menu">


        <li className={`${home.includes(path) ? "mm-active" : ""}`}>
          <Link to="" className="ai-icon" >
            <i className="fas fa-home"></i>
            <span className="nav-text">                                        {t('Home')}</span>
          </Link>
        </li>

        <li className={`${split.includes(path) ? "mm-active" : ""}`}>
          <Link to="split" className="ai-icon" >
            <i className="bi bi-signpost-split-fill"></i>
            <span className="nav-text">{t('Split')}</span>
          </Link>
        </li>

        <li className={`${merge.includes(path) ? "mm-active" : ""}`}>
          <Link to="merge" className="ai-icon" >
            <i className="bi bi-circle-square"></i>
            <span className="nav-text">{t('Merge')}</span>
          </Link>
        </li>


        <li className={`${rotate.includes(path) ? "mm-active" : ""}`}>
          <Link to="rotate" className="ai-icon" >
            <i className="bi bi-arrow-clockwise"></i>
            <span className="nav-text">{t('Rotate')}</span>
          </Link>
        </li>

        <li className={`${about.includes(path) ? "mm-active" : ""}`}>
          <Link to="about" className="ai-icon" >
            <i className="bi bi-info-circle-fill"></i>
            <span className="nav-text">{t('About')}</span>
          </Link>
        </li>



      </MM>

      { /*

      <div className="dropdown header-profile2 ">
        <div className="header-info2 text-center">
          <img src={profile} alt="" />
          <div className="sidebar-info">

            {fichiers.map((f) => <h5>{f}</h5>)}
            <h5>123123</h5>
            <div>
              <h5 className="font-w500 mb-0">William Johanson</h5>
              <span className="fs-12">williamjohn@mail.com</span>
            </div>
          </div>
          <div>
            <Link to={"#"} className="btn btn-md text-secondary">Contact Us</Link>
          </div>
        </div>
      </div>

*/}
      <div className="copyright ">
        <p>Massicot <span className="rouge">PDF</span></p>
        <p>Made with <span className="heart "></span></p>
      </div>

    </div >
  );
};

export default SideBar;
