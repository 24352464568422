import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";


const NavHader = () => {
	const [toggle, setToggle] = useState(false);
	const { openMenuToggle } = useContext(
		ThemeContext
	);
	return (
		<div className="nav-header">
			<Link to="/" className="brand-logo">

				<Fragment>
					<div className="brand-title">
						Massicot
					</div>
					<div className="logo-abbr">
						<i className="fas fa-file-pdf rouge"></i>
					</div>
				</Fragment>

			</Link>

			<div
				className="nav-control"
				onClick={() => {
					setToggle(!toggle);
					openMenuToggle();
				}}
			>
				<div className={`hamburger ${toggle ? "is-active" : ""}`}>
					<span className="line"></span>
					<span className="line"></span>
					<span className="line"></span>
				</div>
			</div>
		</div>
	);
};

export default NavHader;
